import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


// Sample data for the cards
const cards = [
  {
    title: 'Vesting Contracts',
    description: 'Our smart contracts handle vesting to ensure a fair and transparent token distribution.',
    buttonText: 'Learn More',
    link: '/vesting' // specify the link to the Vesting page here
  },
  {
    title: 'Liquidity Pool',
    description: 'We provide a robust liquidity pool to facilitate easy trading and price stability.',
    buttonText: 'Learn More',
    link: '/liquidity-pool' // specify the link to the Liquidity Pool page here
  },
  {
    title: 'Security',
    description: 'Security is our top priority. Our contracts are audited by leading security firms.',
    buttonText: 'Learn More',
    link: '/security' // specify the link to the Security page here
  },
  {
    title: 'Audits',
    description: 'Transparency is key. View our latest audit reports for peace of mind.',
    buttonText: 'View Reports',
    link: '/audits' // specify the link to the Audits page here
  },
];

export default function Project() {
  return (
    <Container sx={{ py: 8 }} maxWidth="lg" align="center">
    <Box align="center" sx={{ minWidth: 275 }}>
      {cards.map((card, index) => (
        <Card key={index} variant="outlined">
          <CardContent>
            <Typography align="Left" sx={{ fontSize: 32 }} color="text.secondary" gutterBottom>
              {card.title}
            </Typography>
            <Typography align="Left" sx={{ fontSize: 24 }} variant="body1">
              {card.description}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" href={card.link}>
              {card.buttonText}
            </Button>
          </CardActions>
        </Card>
      ))}
    </Box>
    </Container>
  );
}
