import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


const steps = [
  {
    label: 'Token Standardization',
    description: `Develop a standardized ERC-20 token to ensure reliability and security for both developers and investors.`,
    done: 'False'
  },
  {
    label: 'Build Marketplace',
    description: `Develop a standardized and intuitive marketplace that provides all the information required about the tokens`,
    done: 'False'
  },
  {
    label: 'Platform Launch',
    description: `Initial launch of the Basecamp platform, offering a simplified way for developers to deploy and manage tokens.`,
    done: 'False'
  },
  {
    label: 'Investment Assurance',
    description: `Implement mechanisms to protect investors from rug pulls and fraudulent projects, offering a safer investment environment.`,
    done: 'False'
  },
  {
    label: 'Community Building',
    description: `Begin community-building efforts to bring together developers, investors, and enthusiasts in the crypto space.`,
    done: 'False'
  },
  {
    label: 'Expand Token Types',
    description: `Introduce other types of tokens (e.g., ERC-721) to provide a more diverse set of investment opportunities.`,
    done: 'False'
  },
  {
    label: 'Advanced Analytics',
    description: `Introduce advanced analytics for investors and developers to gain insights into token performance and usage.`,
    done: 'False'
  },
  {
    label: 'Expansion',
    description: `Expand the platform for other services and third parties and as well other Dex platforms on liquidity pools`,
    done: 'False'
  },
];


export default function Roadmap() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Container sx={{ py: 8 }} maxWidth="lg" align="center">
    <Box
      align="center" 
      sx={{ maxWidth: 600 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography> Discuss with the community for continuos improvement</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
    </Container>
  );
}
