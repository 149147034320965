import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ResponsiveAppBar from './Appbar';
import Main from './main';
import Overview from './Overview';
import Roadmap from './RoadMap';
import Project from './Project';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.thebasecamplabs.com">
        Basecamp Labs
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const theme = createTheme();

export default function App() {
  return (
    <Router>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>  
        <ResponsiveAppBar></ResponsiveAppBar>
        </Toolbar>
      </AppBar>
        {/* Hero unit */}
  <Box
    sx={{
      bgcolor: 'background.paper',
      pt: 8,
      pb: 6,
    }}
  >
    <Container maxWidth="sm">
      <Typography
        component="h1"
        variant="h2"
        align="center"
        color="text.primary"
        gutterBottom
      >
        Basecamp Labs  
      </Typography>
      <Typography variant="h5" align="center" color="text.secondary" paragraph>
        Web3 launch platform for earthlings
      </Typography>
      <Stack
        sx={{ pt: 4 }}
        direction="row"
        spacing={2}
        justifyContent="center"
      >
        <Button variant="contained" a href="Overview" > More about </Button>
        <Button variant="contained" a href="https://www.tokenbasecamp.finance"> Platform Dashboard </Button>
      </Stack>
    </Container>    
  </Box>
      <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/Overview" element={<Overview />} />
          <Route path="/Roadmap" element={<Roadmap />} />
          <Route path="/Project" element={<Project />} />
      </Routes>
    

      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          Contact Us
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
          >
          For any queries, reach out to developer@thebasecamplabs.com
        </Typography>
        <Copyright />
      </Box>
      
      {/* End footer */}
    </ThemeProvider>
    </Router>
  );
}
