import * as React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import PDF from './whitepaper.pdf';
import ScrollDialog from './scroll';
import Deploy from './deploy.png';
import Build from './build.png';
import Invest from './invest.png';


const cards = [
  {id: 1, title: 'Deploy', content: 'Deploy new tokens with easy that are reliable and secure', cardImage: Deploy},
  {id: 2, title: 'Invest', content: 'Invest in new projects with peace of mind of not been rug pulled', cardImage:Invest},
  {id: 3, title: 'Build', content: 'Build that project that is in your mind and release your full potential', cardImage:Build}
];

const Main = () => {
  return       <main>
  <Container sx={{ py: 8 }} maxWidth="md">
    {/* End hero unit */}
    <Grid container spacing={4}>
      {cards.map((card) => (
        <Grid item key={card} xs={12} sm={6} md={4}>
          <Card
            sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <CardMedia
              component="div"
              sx={{
                pt: '56.25%',
              }}
              image={card.cardImage}
            />
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography gutterBottom variant="h5" component="h2">
                {card.title}
              </Typography>
              <Typography>
              {card.content}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small"X >View</Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</main>;
};

export default Main;