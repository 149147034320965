import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import OverviewImage from './overview.jpg';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';


export default function Overview() {
  return (
    <Container sx={{ py: 8 }} maxWidth="lg" align="center">
      <Card
        align="center"
        sx={{ maxWidth: 800 }}>

        <CardActionArea>
          <CardMedia
            component="img"
            height="600"
            image={OverviewImage}
            alt="Overview"
            align="center"
          />
          <CardContent align="center">
            <Typography gutterBottom variant="h4" component="h2">
              Basecamp token platform
            </Typography>
            <Divider textAlign="left"> </Divider>
            <Typography variant="body1" color="text.secondary" component="div">

              Hey there, adventurous crypto-climber! Ready to conquer the towering peaks of the blockchain universe? Well, you're in luck, because Basecamp Labs is your ultimate "basecamp" for launching into the crypto-Everest!
              <Divider textAlign="left"> </Divider>
              <Typography gutterBottom variant="h5" component="h5">Setting Up Camp  </Typography>


              Much like preparing to scale the highest mountain on Earth, venturing into the crypto world requires sturdy gear and rock-solid planning. That's where Basecamp Labs comes in. We're the Sherpas of the token world, guiding you through the treacherous terrain of ERC-20 tokens, and no, we don't charge extra for oxygen tanks. 😜
              <Divider textAlign="left"> </Divider>
              <Typography gutterBottom variant="h5" component="h5">From Hill to Thrill </Typography>



              Why start your climb from the bottom when you can have a headstart? Our platform is the perfect elevation to make sure you're not short of breath (or short of crypto) as you aim for the peak. Think of us as your acclimatization zone, but for tokens!
              <Divider textAlign="left"> </Divider>
              <Typography gutterBottom variant="h5" component="h5"> Rug Pulls </Typography>



              Worried about avalanches? In the crypto world, they're called "rug pulls," and we know they can freeze an investment faster than a blizzard on a Himalayan summit. Luckily, our avalanche warning systems (AKA investment assurance protocols) are designed to keep you from tumbling down into the abyss.

              <Divider textAlign="left"></Divider>
              <Typography gutterBottom variant="h5" component="h5">Ascend with Assurance 🗺️</Typography>


              You wouldn't climb Everest without a map, so why venture into crypto without one? Our roadmap is your guide through the crevasses and pitfalls of the digital asset landscape. And don't worry, our maps are 100% Yeti-free!

              <Divider textAlign="left"></Divider>
              <Typography gutterBottom variant="h5" component="h5">Conquer the Crypto Summit </Typography>

              Ready to plant your flag on summit? Strap on your crampons, adjust your harness, and make sure your tokens are securely tied to your blockchain! With Basecamp Labs, the only way is up—literally!

            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary">
            Share
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
}
